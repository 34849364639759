@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Light.woff2') format('woff2'),
      url('../fonts/Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Book.woff2') format('woff2'),
      url('../fonts/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
      url('../fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
      url('../fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
      url('../fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}





:root {
  --bs-body-bg: #000;
  --bs-body-color: #fff;
  --bs-white-rgb: 255, 255, 255;
  --color-white: #fff;
  --gotham-font: 'Gotham';
  --roboto-font: 'Roboto';
}
html.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}
body{
  font-family: var(--gotham-font);
  font-size: 16px;
  font-weight: 300; 
}
body::-webkit-scrollbar {
  height: 0;
  width: 0
}
body::after {
  position: fixed;
  content: "";
  right: 80px;
  width: 1px;
  height: 100%;
  background: rgba(255,255,255,0.5);
  top: 0;
  z-index: 1;
}
h1,h2,h3,h4,h5,h6{
  font-family: var(--roboto-font);
  font-weight: 500;
}
h1,h2{
  font-size: 32px;
  line-height: 38px;
}
h4{
  font-size: 22px;
  line-height: 28px;
}
ul {
  list-style-type: none;
  padding-left: 0;
}
p:last-of-type{
  margin-bottom: 0;
}
a {
  text-decoration: none;
}
img{
max-width: 100%;
}
div .btn-primary,
div .btn-primary:hover,
div .btn-primary:focus,
div .btn-primary:active,
:not(.btn-check)+.btn:active,
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{  
  background-color: rgba(var(--bs-white-rgb),0.25);
  border: none;
  font-size: 14px;
  font-family: var(--roboto-font);
  line-height: 18px;
  padding: 19px 20px;
  min-width: 200px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  --bs-bg-opacity: 1;  
  letter-spacing: 0.15em;
  border-radius: 50px;
  text-transform: uppercase;
}
div .btn-primary:hover{
  background-color: rgba(var(--bs-white-rgb),0.35);
}
label.form-label {
  font-weight: 500;
  font-family: var(--roboto-font);
  letter-spacing: 0.02em;
}
.form-check-input[type=radio]{
  display: none;
}
.form-check-input[type=radio] + label,
.react-datepicker__input-container {
    background-color: rgba(var(--bs-white-rgb),0.25);
    border: none;
    font-size: 12px;
    font-family: var(--roboto-font);
    line-height: 18px;
    padding: 17px 39px 14px;
    min-width: 200px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    --bs-bg-opacity: 1;
    letter-spacing: 0.15em;
    border-radius: 50px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}
.form-check-input[type=radio]:checked + label {
  background: rgba(var(--bs-white-rgb),1);
  color: #000;
  font-weight: 500;
}
.overlay-15::before {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #000;
  top: 0;
  left: 0;
  content: "";
  opacity: 15%;
}
.letter-spacing-15 {
  letter-spacing: 0.15em;
}
.letter-spacing-20 {
  letter-spacing: 0.2em;
}
.font-gotham{
  font-family: var(--gotham-font);
}
.font-roboto,
body .react-datepicker{
  font-family: var(--roboto-font);
}
.font-38{
  font-size: 38px;
  line-height: 46px;
}
.font-26{
  font-size: 26px;
  line-height: 32px;
}
.font-22{
  font-size: 22px;
  line-height: 28px;
}
.font-14{
  font-size: 14px;
  line-height: 18px;
}
.font-12{
  font-size: 12px;
  line-height: 15px;
}
.ptb-200{
  padding-top: 200px;
  padding-bottom: 200px;
}
.pt-40{
  padding-top: 40px;
}
.pt-200{
  padding-top: 200px;
}
.plr-100{
  padding-left: 100px;
  padding-right: 100px;
}
.mt-200{
  margin-top: 200px;
}

.flex-gap-20{
  gap: 20px;
}

#appinner,
div#myapp{
  overflow-x: hidden;
}

/* Header Css */
a.header_logo {
  margin-right: auto;
}
.header_logo img {
  max-width: 100px;
}
header ul li:not(:last-child) {
  margin: 0 23px 0 0;
}
.header_plus_button {
  background-color: rgb(255,255,255,0.2);
  position: relative;
  z-index: 1111111;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 7px 20px;
  border-radius: 38px;
}
.home_banner:not(.enqury_banner) .fixed_buttons {
  padding: 0 54px 0px 0;
  bottom: 54px;
}
.header_plus_button span.rounded-circle svg {
  height: 14px;
  width: 14px;
  margin-left: 10px;
}
.fixed_buttons li a {
  padding: 19px 20px;
  min-width: 200px;
  backdrop-filter: blur(5px);
}
.mobile_side_menu {
  width: calc(100% - 30px);
  background: rgba(0,0,0,0.5);
  height: 100%;
  backdrop-filter: blur(3px);
  padding: 100px 30px 30px;
  right: -100%;
  top: 0;
  transition: all ease 0.3s;
}
header.menu-open + .mobile_side_menu{
  right: 0;
}
.mobile_side_menu .header_plus_button {
  background-color: #fff6;
  padding: 13px 20px;
  display: flex;
  -webkit-align-items: center;
  align-items: center;  
  justify-content: center;
  line-height: inherit;
}
.mobile_side_menu .header_plus_button svg {
  width: 14px;
  height: 14px;
  margin-left: 7px;
}
.mobile_side_menu ul.rightmenu li:not(:first-child) {
  padding-left: 20px;
}
.menuclose{
  top: 30px;
  right: 30px;
}

/* Banner Section Start */
.home_banner::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  content: "";
  position: absolute;
  height: 255px;
  width: 100%;
  left: 0;
  bottom: -2px;
}
.home_banner_content {
  padding-left: 100px;
  padding-bottom: 256px;
  padding-top: 146px;
}
.home_banner_bottom {
  max-width: 480px;
}
.fixed_buttons{
  position: fixed;
  right: 0;
}


@keyframes blurToNormal {
  0% {
    filter: blur(10px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}

.defalutblur {
  filter: blur(10px);
  opacity: 0;
  animation: blurToNormal 1s forwards;
}

.blurred {
  filter: blur(5px);
  transition: filter 0.5s ease-in-out;
}

/* Banner Section End */

/* Left Right Image Start */
.left_right_leftcol{
  padding-right: 76px;
}
.left_right_rightcol p {
  max-width: 480px;
}
.leftright_desktop .left_right_leftcol {
  padding-right: 1rem;
  padding-left: 90px;
}
.leftright_desktop .left_right_rightcol_top {
  max-width: 480px;
}
.leftright_desktop .left_right_leftcol video {
  height: 500px;
  width: 100%;
  object-fit: cover;
}
.leftright_desktop .left_right_rightcol img,
.leftright_desktop .left_right_rightcol video {
  height: 300px;
  object-fit: cover;
  width: 100%;
}
.leftright_desktop .left_right_rightcol > img:first-child,
.left_right_rightcol_div{
  max-width: 80%;
  transition: all ease 0.3s;
}
.left_right_rightcol_div.active {
  max-width: 100%;
}


.westrive_topimgrow_inner {
  display: flex;
  align-items: flex-end;
  gap: 25px;
  margin-bottom: 25px;
}
.westrive_topimg_col {
  padding-bottom: 30%;
  position: relative;
}
.westrive_topimgrow_inner .westrive_topimg_col:first-child {
  flex: 0 0 10%;
  max-width: 10%;
}
.westrive_topimgrow_inner .westrive_topimg_col:nth-child(2) {
  flex: 0 0 50%;
  max-width: 50%;
}
.westrive_topimg_col:last-child {
  flex: 0 0 45%;
  max-width: 45%;
}
.westrive_topimg_col img, .westrive_topimg_col video {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  bottom: 0;
}
.westrive_bottom_imgrow .westrive_topimgrow_inner .westrive_topimg_col:first-child {
  flex: 0 0 25%;
  max-width: 25%;
}
.westrive_bottom_imgrow .westrive_topimgrow_inner .westrive_topimg_col:nth-child(2) {
  flex: 0 0 40%;
  max-width: 40%;
}
.westrive_bottom_imgrow .westrive_topimg_col:last-child {
  flex: 0 0 35%;
  max-width: 35%;
}
.westrive_bottom_imgrow .westrive_topimg_col img, 
.westrive_bottom_imgrow .westrive_topimg_col video{
  bottom: auto;
  top: 0;
}
.westrive_topimgrow_inner .westrive_topimg_col:first-child img, 
.westrive_topimgrow_inner .westrive_topimg_col:last-child img {
  height: calc(100% - 80px);
}
.westrive_bottom_imgrow .westrive_topimgrow_inner .westrive_topimg_col:nth-child(2) img.left_right_rightcol_animateimage {
  z-index: 1;
}
.westrive_tophead {
  padding: 0 100px;
  max-width: 600px;
}

/* Left Right Image End */

/* Service Section Start */
.services{
  padding-left: 156px;
  padding-right: 260px;
}
.service_icon{
  height: 50px;
  width: 50px;
  margin-right: 55px;
}
.listwithdots li{
  font-size: 11px;
}
.listwithdots li:not(:last-child)::after {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 24px;
  display: inline-block;
}
/* Service Section End */

/* Slider Section Start */
.slider_outer{
  padding-bottom: 150px;
  background: var(--bs-body-bg);
}
.slider_wrapper .swiper-wrapper {
  align-items: center;
  padding: 58px 0;
}
.slider_wrapper .swiper-slide {
  transition: all 200ms linear;
  opacity: .2;
}
.slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide {
  transform: scale(1.22);
  opacity: 1;
  z-index: 1;
}
.common_slider_slide{
  height: 425px;
}
.slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide .common_slider_slide{
  height: 515px;
}
.slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide .common_slider_slide::before,
.slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide .common_slider_slide::after {
  position: absolute;
  content: "";
  width: 100%;  
  left: 0;  
}
.common_slider_slide::before{
  height: 272px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  top: -1px;
  z-index: 1;
}
.common_slider_slide::after{
  height: 162px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
  bottom: -1px;

}
.common_slider_slide_text{
  padding: 45px 0 58px;
  opacity: 0;
}
.slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide .common_slider_slide_text{
  opacity: 1;
}
.common_slider_slide_text  h6 {
  margin-left: -67px;
}
.common_slider_slide_text  h2 {
  align-self: end;
  margin-right: -112px;
}
.sliderarrow{
  background: transparent;
  height: 50px;
  line-height: 39px;
  transition: all .3s ease;
  width: 50px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider_outer .sliderarrow.arrow-right {
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.slider_wrapper .swiper-scrollbar-drag{
  display: none;
}
/* Slider Section End */

/* Reviews Section Start */
.reviews{
  padding: 0 80px 800px;
  position: relative;
}
.reviews_right .common_review_image {
  min-height: 290px;  
}
.common_review_box{
  position: relative;
}
.common_review_content{
  opacity: 0;
  position: absolute;
  max-width: 550px;
}
.common_review_box5 video.position-absolute {
    object-position: bottom center;
}
.common_review_content1 {
  right: -55%;
  bottom: -100%;
}
.common_review_content2 {
  right: -80%;
  bottom: -180%;
  text-align: right;
}
.common_review_content3{
  left: -40%;
  top: 5%;
}
/* Reviews Section End */

/* Footer Section Start */
footer::before,
footer::after{
  position: absolute;
  content: "";
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
footer::before {  
  height: 365px;  
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);  
}
footer::after {
  height: 100%;
  background: #000;
  opacity: 0.3;
}
.top_footer,
.bottom_footer{
  padding: 0 100px;
}
.top_footer{
  min-height: 680px;
}
.footer_social.contact_social li a {
  background: rgba(255,255,255,0.2);
  border: none;
  height: 40px;
  width: 40px;
  opacity: 1;
}
.footer_social.contact_social li a svg{
  opacity: 1;
}
/* Footer Section End */

/* Enqury Page Start */
.enqury_banner  .home_banner_content,
.affiliate_banner  .home_banner_content {
  padding-right: 68px;
}
.enqury_buttons {
  bottom: -20px;
}
.enqury_buttons li a,
.budget_radios .form-check-input[type=radio] + label {
  padding: 17px 39px 14px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  font-size: 11px;
}
.budget_radios .form-check-input[type=radio] + label {
  height: 50px;
}
.budget_radios {
  gap: 20px;
  max-width: 450px;
}
input.form-control, textarea.form-control {
  background: #0d0d0d;
  border: 1px solid #4a4a4a;
  font-size: 12px;
  font-family: var(--roboto-font);
  letter-spacing: 0.15em;
  padding: 12px 30px;
  border-radius: 10px;
}
input,input.form-control:focus, textarea.form-control:focus {
  background: #0d0d0d;
  outline: none;
  box-shadow: none;
  border: 1px solid #797979;
}
input.form-control {
  height: 50px;
}
input::-ms-input-placeholder,
input.form-control::-ms-input-placeholder {
  text-transform: uppercase;
  color: var(--color-white);
}
input.form-control::placeholder,
input::placeholder {
  text-transform: uppercase;
  color: var(--color-white);
}
.info_fields{
  width: 85%;
}
.info_fields > div{
  width: calc(50% - 10px);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.enqury_form_container .react-datepicker__input-container input {
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--roboto-font);
  letter-spacing: 0.15em;
}
.enqury_form_container .react-datepicker__input-container {
  padding: 0 39px;
}
.enqury_form_container .react-datepicker__input-container input {
  height: 50px;
}
.enqury_form div#destination + div + div {
    position: relative;
    z-index: 11;
}
.enqury_form_container .react-datepicker__input-container svg.react-datepicker__calendar-icon {
  padding: 0;
  color: var(--color-white);
  fill: var(--color-white);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.common_enqury_slider .slider_slide::after {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  height: 70%;
  width: 100%;
  bottom: 0;
}
.travel_slider_wrapper .slider_slide {
  padding-bottom: 67.5%;
}
.boat_type_slider_wrapper .slider_slide {
  padding-bottom: 63.5%;
}
.arrow-right1.sliderarrow.enquryslider_arrow, .arrow-right2.sliderarrow.enquryslider_arrow {
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.common_enqury_slider .swiper-slide.swiper-slide-visible:not(.swiper-slide-fully-visible){
  opacity: 40%;
}
.common_enqury_slider .swiper-slide.swiper-slide-visible.swiper-slide-active {
  opacity: 1;
}
.swiper-wrapper .swiper-slide > div{
  border: 1px solid transparent;
}
.swiper-wrapper .swiper-slide > div.countrySlider,
.swiper-wrapper .swiper-slide > div.boatSlider {
  border: 1px solid rgba(255,255,255,0.5);
  box-shadow: 0px 0px 20px rgb(255,255,255,0.6);
}
/* Enqury Page End */

/* Affiliates Signup Start  */

.aboutpage .affiliate_banner.overlay-15::before {
    opacity: 15%;
}
.enqury_banner.enqury_banner_new.overlay-15::before {
  opacity: 40%;
}
.affiliate_banner .home_banner_content {
  padding-bottom: 44px;
}
.affiliates_banner_bottom{
  max-width: 810px;
}
.affiliates_form_wrap .info_fields {
  width: 100%;
}
.affiliates_form_wrap_inner {
  max-width: 600px;
}
.swiper-slide {
  padding: 1px 0;
}
/* Affiliates Signup End  */
/* About Start */
.about_banner_bottom {
  max-width: 607px;
}
.about_banner::after {
  height: 500px;
}
.about_content {
  max-width: 880px;
  margin-top: -30px;
  position: relative;
}
.about_content p{
  max-width: 700px;
}
.contact_social li a {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 50%;
  margin: 0 12px 0 0;
}
.contact_social li a svg{
  opacity: 50%;
}
.contact_social li a:hover{
  background-color: #fff;
}
.contact_social li a:hover svg {
  opacity: 1;
  filter: invert(1);
}
/* About End */



@media(min-width: 769px){
  .affiliate_banner img.home_banner_image,
  .enqury_banner img.home_banner_image,
  .main_home_banner video.home_banner_image{
    height: 100vh;
    object-fit: cover;
    width: 100%;
  }
  .enqury_banner img.home_banner_image,
  .main_home_banner video.home_banner_image{
    height: 120vh;
  }  
  body .enqury_buttons ul {
    padding: 0 54px 0px 0;
    bottom: 70px;
    position: fixed;
    right: 0;
  }
}

@media(max-width: 1440px){
  .reviews_right .common_review_image {
    min-height: 264px;
  }  
}
@media(max-width: 1366px){
  .services {
    padding-left: 80px;
    padding-right: 160px;
  }  
}


@media(max-width: 768px){  
  .pt-200{
    padding-top: 100px;
  }
  .mt-200 {
    margin-top: 100px;
  }
  .about_banner img.home_banner_image {
    min-height: 100vh;
  }
  .mobile_side_menu a.header_plus_button span.rounded-circle {
    height: auto;
    width: auto;
  }
  .appinner{
    overflow-x: hidden;
  }
  .font-26 {
    font-size: 20px;
    line-height: 26px;
  }
  .ptb-200 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  body::after {
    right: 40px;
    opacity: 25%;
  }
  .font-22 {
    font-size: 18px;
    line-height: 28px;
  }
  h2{
    font-size: 28px;
    line-height: 32px;
  }
  header.border-bottom {
    border: none !important;
  }
  header,
  .home_banner_content,
  .services,
  .reviews,
  .top_footer, 
  .bottom_footer {
    padding-left: 20px;
    padding-right: 20px;
  }  
  header .rightmenu{
    width: 100%;
  }
  .rightmenu span.rounded-circle {
    width: 38px;
    padding: 0;
    height: 38px;
  }
  /* .rightmenu li:last-child {
    margin-left: auto;
  } */
  .home_banner_content {
    padding-bottom: 80px;
  }
  .service_icon {
    height: 35px;
    width: 35px;
    margin-right: 16px;
  }
  .service_text p {
    font-size: 14px;
    line-height: 22px;
  }
  .top_footer {
    min-height: 555px;
  }
  .service_rightcol_inner {
    padding-right: 38px;
  }
  .fixed_buttons{
    position: static;
  }
  .home_banner:not(.enqury_banner) .fixed_buttons{
    padding: 0;
  }
  .fixed_buttons  ul {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
  }
  .fixed_buttons ul li a {
    min-width: 100%;
    padding: 16px 20px;
  }
  .home_banner_bottom  p {
    max-width: 300px;
  }
  .left_right_rightcol_top {
    padding: 0 100px 0 30px;
  }
  .left_right_leftcol {
    padding-right: 20px;
    padding-top: 30px;
  }
  .left_right_rightcol_top p {
    line-height: 25px;
    max-width: 294px;
  }
  .left_right_rightcol {
    height: 443px;
  }
  .left_right_leftcol video {
    height: 413px;
    object-fit: cover;
    display: block;
  }
  .left_right_rightcol img.left_right_rightcol_image1 {
    height: 100%;
    margin: 0;
    object-fit: cover;
  }
  .leftrightbtmimg{
    margin-top: 20px;
  }
  .leftrightbtmimg img {
      height: 225px;
      width: 100%;
      object-fit: cover;
  }
  .slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide{
    opacity: 0.2;
  }
  .slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide .common_slider_slide_text {
      opacity: 0;
  }
  .slider_wrapper .swiper-slide.swiper-slide-active,
  .slider_wrapper .swiper-slide.swiper-slide-active .common_slider_slide_text{
    opacity: 1;
  }
  .slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide .common_slider_slide,
  .common_slider_slide {
    height: 304px;
  }
  .slider_wrapper .swiper-slide.swiper-slide-active .common_slider_slide {
    height: 368px;
  }
  .slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide {
    transform: none;
    z-index: 0;
  }
  .slider_wrapper .swiper-slide.swiper-slide-active {
    transform: scale(1.22);
    opacity: 1;
    z-index: 1;  
  }
  .slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide .common_slider_slide::before, .slider_wrapper .swiper-slide.swiper-slide-active + .swiper-slide .common_slider_slide::after {
    display: none;    
  }
  .slider_wrapper .swiper-slide.swiper-slide-active .common_slider_slide::before, .slider_wrapper .swiper-slide.swiper-slide-active .common_slider_slide::after {
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
  }
  .common_slider_slide_text {
    padding: 15px 0 10px;
    opacity: 0;
  }
  .common_slider_slide_text  h2 {
    align-self: start;
    margin-right: 0;
    margin-left: -20px;
  }
  .common_slider_slide_text  h6 {
      margin-left: -20px;
  }
  .sliderarrow {
    height: 42px;
    line-height: 1;
    width: 42px;
    padding: 0;
  }
  .sliderarrow img{
    width: 23px;
  }
  .review_component_mobile p:not(.text-uppercase){
    font-size: 14px;
  }
  .review_component_head {
    padding: 0 50px;
  }
  .common_review_box_mobile1 video {
    height: 225px;
  }
  .common_review_box_mobile .common_review_image_mobile {
    padding-left: 50px;
  }
  .common_review_content_mobile1 {
    padding: 0 60px 0 40px;
    margin: -50px 0 0;
    position: relative;
    z-index: 1;
  }
  .common_review_image_mobile::after {
    position: absolute;
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    height: 70px;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .common_review_content_mobile  h2 {
    font-size: 22px;
  }
  .common_review_content_mobile.common_review_content_mobile2 {
      max-width: 335px;
      text-align: right;
      margin-left: auto;
      padding: 0 30px;
      margin-top: -60px;
  }
  .common_review_content_mobile15 video {
    height: 185px;
  }
  .common_review_content_mobile5 {
    padding-bottom: 58%;
  }
  .common_review_content_mobile5_text  > div {
      margin-top: -25px;
      position: relative;
  }
  .common_review_content_mobile5_text  h2 {
    font-size: 22px;
    line-height: 26px;
  }
  .rightmenu li a {
    font-size: 14px;
  }
  .enqury_banner   .home_banner_content {
    padding-right: 20px;
  }
  .enqury_banner img.home_banner_image,
  .affiliate_banner img.home_banner_image {
    min-height: 110vh;
    object-fit: cover;
  }
  .enqury_buttons ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .enqury_buttons li a {
      padding: 17px 31px 14px;
      margin: 0 10px 10px 0 !important;
  }
  .home_banner::after {
    height: 495px;
  }
  .plr-100 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .boat_type_slider_outer {
    padding-left: 0px;
  }
  .budget_radios {
      gap: 10px;
  }
  .budget_radios  .form-check {
    flex: 0 0 calc(50% - 5px);
  }
  body .budget_radios .form-check-input[type=radio] + label {
      padding: 17px 18px 14px;
      min-width: 100%;
      width: 100%;
  }
  .enqury_normal_fields {
    padding-right: 50px;
  }
  .form-check-input[type=radio] + label {
    padding: 17px 36px 14px;
    min-width: auto;
  }
  .flex-gap-20 {
    gap: 10px;
  }
  div .info_fields > div {
    width: 100%;
  }
  .info_fields {
    width: calc(100% - 30px);
  }
  .affiliates_form_wrap {
    padding-right: 41px;
  }
  header {
    border-bottom: none;
  }
  a.header_logo{
    display: none;
  }
  .about_content {
    margin-top: 0;
    padding-right: 50px;
  }
  video.home_banner_image {
    height: 120vh;
  }
  .main_home_banner video.home_banner_image{
    height: 100vh;
  }
  .main_home_banner .home_banner_content {
    padding-bottom: 20px;
    padding-top: 91px;
  } 
  .common_slider_slide_text  h6 {
      opacity: 0;
      visibility: hidden;
  }
  .slider_outer > h6 {
    padding-left: 20px;
  }
  .about_banner img.home_banner_image {
    min-height: 80vh;
  }
  .enqury_buttons {
    bottom: 0;
  }
  input,
  .enqury_form_container .react-datepicker__input-container input,
  input.form-control, textarea.form-control,
  .budget_radios .form-check-input[type=radio] + label,
  .form-check-input[type=radio] + label{
    font-size: 16px;;
  }
  .slider_outer {
    padding-bottom: 30px;
  }
}
@media(max-width: 480px){  
  .listwithdots li:not(:last-child)::after{
    display: none;
  }
  .listwithdots  li {
    flex: 0 0 100%;
  }
  .listwithdots  li:not(:last-child) {
    margin-bottom: 20px;
  }
  .budget_radios  .form-check {

      flex-basis: 100%;
      font-size: 16px;
  }
}